<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showRightArrow: true,
    showLeftArrow: false,
    canScroll: false,
  }),
  inject: ['product'],
  mounted() {
    this.$nextTick(() => {
      this.canScroll = this.$refs.container && this.$refs.container.scrollLeft + 10 < this.$refs.container.scrollWidth - this.$refs.container.clientWidth;
    });
  },
  computed: {
    title() {
      return this.block.fields.Title;
    },
    productReviews() {
      return this.product.fields.Reviews || [];
    },
    marginTop() {
      return this.block.fields.MarginTop || 0;
    },
    marginBottom() {
      return this.block.fields.MarginBottom || 0;
    },
    hasReviews() {
      return this.productReviews.length > 0;
    },
  },
  methods: {
    canScrollRight() {
      this.showRightArrow = this.$refs.container && this.$refs.container.scrollLeft + 10 < this.$refs.container.scrollWidth - this.$refs.container.clientWidth;
    },
    canScrollLeft() {
      this.showLeftArrow = this.$refs.container && this.$refs.container.scrollLeft > 0;
    },
    getScrollIncrement() {
      return this.$refs.container.scrollWidth / this.productReviews.length;
    },
    goPrev() {
      this.$refs.container.scrollLeft -= this.getScrollIncrement();
    },
    goNext() {
      this.$refs.container.scrollLeft += this.getScrollIncrement();
    },
    checkScrollPosition() {
      if (this.timeout) clearTimeout(this.timeout);

      const later = () => {
        clearTimeout(this.timeout);
        this.canScrollRight();
        this.canScrollLeft();
      };

      clearTimeout(this.timeout);
      this.timeout = setTimeout(later, 50);
    },
    getRemainingStars(rating) {
      const maxRating = 5;
      return maxRating - rating;
    },
  },
};
</script>

<template>
  <div
    v-if="hasReviews"
    class="_layout-maxWidth"
    :style="{ marginTop: marginTop, marginBottom: marginBottom }"
  >
    <div class="product-reviews">
      <div class="product-reviews_title">{{ title }}</div>
      <div class="product-reviews_list-wrapper">
        <div class="product-reviews_list" ref="container" @scroll="checkScrollPosition()">
          <div class="product-reviews_review" v-for="(review, idx) in productReviews" :key="idx">
            <div class="product-reviews_review-content">
              <div class="product-reviews_review-info">
                <div class="product-reviews_author-name">{{ review.ReviewAuthor }}</div>
                <div class="product-reviews_author-title">{{ review.ReviewAuthorTitle }}</div>
                <div class="product-reviews_review-body">{{ review.ReviewBody }}</div>
                <div class="product-reviews_review-rating">
                  <img
                    class="product-reviews_review-star"
                    src="../static/icons/icon_star_black.svg"
                    v-for="(star, idx) in review.ReviewRating"
                    :key="'a' + idx"
                  />
                  <img
                    class="product-reviews_review-star"
                    src="../static/icons/icon_star_grey.svg"
                    v-for="(star, idx) in getRemainingStars(review.reviewRating)"
                    :key="'b' + idx"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="product-reviews_navigation" v-if="canScroll">
          <a
            href
            @click.prevent="goPrev"
            v-if="showLeftArrow"
            class="product-reviews_navigation-prev"
          ></a>
          <a
            href
            @click.prevent="goNext"
            v-if="showRightArrow"
            class="product-reviews_navigation-next"
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.product-reviews_title {
  font-family: var(--font-body);
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 2.5px;
  margin-bottom: 26px;
  text-transform: uppercase;
}

.product-reviews_list {
  display: flex;
  flex-direction: row;
  scroll-snap-type: x mandatory;
  scroll-snap-align: center;
  scroll-padding: 0 2rem;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0.5rem -0.5rem 30px;
}

.product-reviews_review {
  list-style-type: none;
  width: calc(100% - 4rem);
  padding: var(--half-grid-margin);
  flex-shrink: 0;
  scroll-snap-align: start;
}

.product-reviews_review-content {
  width: 100%;
  height: 100%;
  color: var(--color-black);
}

.product-reviews_review-info {
  display: flex;
  flex-direction: column;
  width: calc(100% - 1rem);
  height: 100%;
}

.product-reviews_author-name {
  font-family: var(--font-header);
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 4px;
}

.product-reviews_author-title {
  font-family: var(--font-body);
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.17px;
  margin-bottom: 11px;
  text-transform: uppercase;
}

.product-reviews_review-body {
  margin-top: 10px;
  font-size: 16px;
  line-height: 19px;
}

.product-reviews_review-rating {
  margin-top: auto;
  display: flex;
  align-items: center;
  margin: 0 -0.25rem;
}

.product-reviews_review-star {
  height: 15px;
  width: 15px;
  margin: 1rem 0.25rem 0;
}

.product-reviews_list::-webkit-scrollbar {
  display: none;
}

.product-reviews_navigation {
  display: flex;
  height: 24px;
}

.product-reviews_navigation-prev {
  background-image: url(../static/icons/left-arrow.svg);
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}

.product-reviews_navigation-next {
  background-image: url(../static/icons/right-arrow.svg);
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin-left: auto;
}

@media (--tabletAndDesktop) {
  .product-reviews {
    flex-direction: row;
    margin: auto;
  }

  .product-reviews_title {
    display: block;
    margin-top: 1rem;
    line-height: 19px;
    margin-bottom: 39px;
    font-weight: bold;
  }

  .product-reviews_list-wrapper {
    width: 100%;
    padding: 0 2rem;
  }

  .product-reviews_list {
    scroll-padding: 0;
  }

  .product-reviews_review {
    width: 33.333%;
  }

  .product-reviews_author-name {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 9px;
  }

  .product-reviews_author-title {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 2.5px;
    margin-bottom: 15px;
    font-weight: bold;
  }

  .product-reviews_review-body {
    margin-top: 15px;
    font-size: 16px;
    line-height: 19px;
  }

  .product-reviews_review-rating {
    margin-top: auto;
    display: flex;
    align-items: center;
  }

  .product-reviews_review-star {
    height: 19px;
    width: 19px;
  }
}
</style>
